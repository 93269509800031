import React from "react";
import "./DividerWrapper.css";

const DividerWrapper = ({ children }) => {
  return (
    <div className="content-with-divider">
      <div className="divider-item"></div>
      {children}
      <div className="divider-item"></div>
    </div>
  );
};

export default DividerWrapper;
