import React, { useRef, useState } from "react";
import axios from "axios";
import "./FourthSection.css";
import DividerWrapper from "../components/DividerWrapper";

const __successClassName = "successText";
const __errorClassName = "errorText";
const __placeHolderClassName = "feedbackPlaceHolder";

const __placeHolderFeedback = {
  className: __placeHolderClassName,
  message: <>&nbsp;</>,
};

function SecondSection() {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const [feedback, setFeedback] = useState(__placeHolderFeedback);
  const [loading, setLoading] = useState(true);

  const displayError = () => {
    setFeedback({
      className: __errorClassName,
      message: "Something went wrong!",
    });
  };

  const displaySuccess = () => {
    setFeedback({
      className: __successClassName,
      message: "Your message was sent successfully!",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbzMms5WWJ-SJFznNm9vuqxiKbCFMsiMUULP1VgzUs8NYqTfgYFtyT9zA7_1Sw5S5eVkrg/exec",
        JSON.stringify({
          name: nameRef.current.value,
          email: emailRef.current.value,
          message: messageRef.current.value,
        }),
        {
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        }
      );

      if (
        !response.data ||
        !response.data.result ||
        !response.data.result === "success"
      ) {
        throw new Error("Something went wrong!");
      }

      displaySuccess();
      nameRef.current.value = "";
      emailRef.current.value = "";
      messageRef.current.value = "";
    } catch (e) {
      displayError();
    }

    setLoading(false);
  };

  return (
    <div className="fourth-section-base">
      <div id="fourth-section" className="fourth-section">
        <span>
          <img src="logo-text.png" height={40} />
          <h1 className="section-title">Contact Us</h1>
          <DividerWrapper>
            <h3 className="section-title">Rich Desserts Experience</h3>
          </DividerWrapper>
        </span>

        <div className="inner-contact">
          <div className="info-contact">
            <h2>To become an Official agent:</h2>

            <p>
              Contact <i>sales@dukesvapes.com</i>
            </p>
          </div>

          <div className="input-contact">
            <form onSubmit={handleSubmit}>
              <span className="responseMessage"></span>
              <input
                placeholder="Name"
                ref={nameRef}
                className="custom-text-input"
                required
              />
              <input
                placeholder="Email"
                type="email"
                ref={emailRef}
                className="custom-text-input"
                required
              />
              <textarea
                placeholder="Message"
                type="text"
                ref={messageRef}
                className="custom-text-input"
                required
              ></textarea>
              <div className={feedback.className}>{feedback.message}</div>

              <input
                type="submit"
                value={"Send Message"}
                className={loading ? "sendButtonDisabled" : "sendButton"}
                disabled={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
