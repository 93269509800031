import React from "react";
import "./SecondSection.css";
import DividerWrapper from "../components/DividerWrapper";

function SecondSection() {
  return (
    <div className="second-section-base">
      <div id="second-section" className="second-section">
        <span>
          <img src="logo-text.png" height={40} />
          <h1 className="section-title">Welcome to Duke's Hall</h1>
          <DividerWrapper>
            <h3 className="section-title">An enriching & savoury experience</h3>
          </DividerWrapper>
        </span>
      </div>
    </div>
  );
}

export default SecondSection;
