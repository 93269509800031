import AppBar from "./components/AppBar";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";
import FourthSection from "./sections/FourthSection";
import "./App.css";

function App() {
  return (
    <div className="app">
      <AppBar />

      <div className="main-content">
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
      </div>
    </div>
  );
}

export default App;
