import React, { useEffect, useRef } from "react";
import { FaArrowDown } from "react-icons/fa";
import "./VideoBackground.css";

function VideoBackground({ isLoading, onLoad }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleLoadedData = () => {
      onLoad();
    };

    const video = videoRef.current;
    video.addEventListener("loadeddata", handleLoadedData);

    return () => {
      video.removeEventListener("loadeddata", handleLoadedData);
    };
  }, [onLoad]);

  const scrollToSection = (event) => {
    event.preventDefault(); // Prevent the default jump behavior
    const section = document.querySelector("#second-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="video-background">
      <video ref={videoRef} autoPlay loop muted>
        <source src="bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <a
        href="#second-section"
        className="arrow-down"
        onClick={scrollToSection}
      >
        <FaArrowDown size={36} />
      </a>
    </div>
  );
}

export default VideoBackground;
